export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const capitalizeFirst = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validateEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    // eslint-disable-line
    return true;
  } else return false;
};
