import React, { useState, useEffect, useContext } from "react";
import { Formik, Field, Form } from "formik";
import { Input } from "../../components/Forms";
import { capitalizeFirst } from "../../utils";
import Spinner from "../../components/Spinner";
import { register } from "../../services/firebase";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import * as Yup from "yup";
import { AuthContext } from "../../contexts";
import mixpanel from "../../services/mixpanel";

const Register = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const [authError, setAuthError] = useState(null);
  const { hasLoaded, authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedMixpanel) {
      mixpanel.track("View register page");
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel]);

  useEffect(() => {
    if (hasLoaded && authenticated) {
      navigate("/");
    }
  }, [hasLoaded, authenticated, navigate]);

  const onSubmit = async ({
    email,
    password,
    firstName,
    lastName,
    company,
  }) => {
    setSubmitting(true);

    const createNewUser = async ({
      email,
      password,
      firstName,
      lastName,
      company,
    }) => {
      mixpanel.track("Registered");
      const res = await register({
        email,
        password,
        firstName,
        lastName,
        company,
      });
      if (res.error) {
        setAuthError(res.error.message);
      } else {
        return null;
      }
    };

    createNewUser({
      email,
      password,
      firstName: firstName ? capitalizeFirst(firstName.toLowerCase()) : null,
      lastName: lastName ? capitalizeFirst(lastName.toLowerCase()) : null,
      company,
    });
  };

  const renderAuthError = () => {
    if (authError) {
      return (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              {/* <!-- Heroicon name: check-circle --> */}
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">{authError}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setAuthError(null)}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    company: Yup.string().required("Required"),
    email: Yup.string()
      .email("Please input a valid email")
      .required("required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
    passwordConfirm: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Password confirmation does not match"
      )
      .required("Required"),
  });

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* Logo Here */}
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Kaida" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
          Let's Get Started!
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {renderAuthError()}
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              passwordConfirm: "",
              company: "",
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form className="space-y-6" action="#" method="POST">
              <div className="mt-6">
                <Field
                  name="firstName"
                  label="First Name"
                  type="text"
                  component={Input}
                />
              </div>
              <div className="mt-6">
                <Field
                  name="lastName"
                  label="Last Name"
                  type="text"
                  component={Input}
                />
              </div>
              <div className="mt-6">
                <Field
                  name="email"
                  label="Email"
                  type="email"
                  component={Input}
                />
              </div>
              <div className="mt-6">
                <Field
                  name="company"
                  label="Company"
                  type="company"
                  component={Input}
                />
              </div>
              <div className="mt-6">
                <Field
                  name="password"
                  label="Password"
                  type="password"
                  component={Input}
                />
              </div>
              <div className="mt-6">
                <Field
                  name="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  component={Input}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full text-center flex justify-center py-2 px-4 rounded-md shadow-sm border border-transparent text-sm font-medium text-white bg-rose-500 hover:bg-rose-600"
                  disabled={submitting}
                >
                  {submitting ? (
                    <Spinner height={20} color="#FFFFFF" />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </Form>
          </Formik>
          <div className="relative flex justify-between text-sm">
            <Link
              to="/login"
              className="text-primary-500 hover:text-primary-700 font-medium text-sm mt-6"
            >
              Back To Login
            </Link>
            {/* <a
              href="https://savemywordle.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="text-primary-500 hover:text-primary-700 font-medium text-sm mt-6"
            >
              Privacy Policy
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
