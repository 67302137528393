import React from "react";
import _ from "lodash";

export const Input = ({ field, name, form, label, errors, help, ...props }) => {
  const borderColor =
    form.errors &&
    _.get(form.errors, field.name) &&
    _.get(form.touched, field.name)
      ? "red"
      : "grey";
  const renderLabel = () => {
    if (label) {
      return (
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          {label}
        </label>
      );
    }
  };

  const renderError = () => {
    if (
      form.errors &&
      _.get(form.errors, field.name) &&
      _.get(form.touched, field.name)
    ) {
      return (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {_.get(form.errors, field.name)}
        </p>
      );
    }
  };

  return (
    <>
      {renderLabel()}
      <div className="mt-1 rounded-md shadow-sm relative">
        <input
          id={label}
          {...field}
          {...props}
          autoComplete="on"
          className={`appearance-none block w-full px-3 py-2 border border-${borderColor}-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
        />
      </div>
      {help ? <p className="mt-2 text-xs text-gray-500">{help}</p> : null}
      {renderError()}
    </>
  );
};

Input.defaultProps = {
  type: "text",
};
