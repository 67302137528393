import Register from "./scenes/Auth/Register";
import Waitlist from "./scenes/Waitlist";

export const routes = [
  {
    path: "/",
    element: <Waitlist />,
  },
  {
    path: "/register",
    element: <Register />,
  },
];
