import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Select = ({ label, field, form, options }) => {
  //const [isTouched, setIsTouched] = useState(false);
  const hasError = form.errors && form.errors[field.name];
  const selected = options && field.value
    ? options.find(option => option.value === field.value)
    : null

  const renderError = () => {
    if (hasError) {
      return (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {form.errors[field.name]}
        </p>
      );
    }
  };

  if (options) {
    return (
      <div>
        <Listbox value={field.value} onChange={val => form.setFieldValue(field.name, val)}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button className={`bg-white relative w-full border border-${hasError ? 'red' : 'gray'}-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}>
                  <span className="block truncate text-sm">{selected ? selected.name : 'select...'}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary-500' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={option.value}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {option.name}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-500',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {renderError()}
      </div>
    )
  } else return null
}