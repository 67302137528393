import React, { useEffect, useContext } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../contexts";
import { useNavigate } from "react-router";
import Logo from "../../assets/logo.png";

//waitlist
const Waitlist = () => {
  const { hasLoaded, authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasLoaded && !authenticated) {
      navigate("/register");
    }
  }, [hasLoaded, authenticated, navigate]);

  if (authenticated) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto rounded-lg"
            src={Logo}
            alt="Kaida"
          />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center">
              <CheckCircleIcon className="text-green-500 h-10" />
            </div>
            <h2 className="mt-6 text-center text-xl font-medium text-gray-900">
              Thanks for submitting!
            </h2>
            <div className="text-center pt-5">
              <p className="text-gray-500">
                Kaida is currently invite-only. Your name and email have been
                added to our waitlist, but feel free to reach out to us if you
                would like access early. We will also be in touch as soon as
                possible to give you more info. We appreciate it and look
                forward to working with you!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="min-h-screen bg-gray-50 flex text-center flex-col justify-center py-12 sm:px-6 lg:px-8">
        Loading...
      </div>
    );
  }
};

export default Waitlist;
