import React, { useContext, useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import mixpanel from "./services/mixpanel";
import { AuthContext } from "./contexts";

const router = createBrowserRouter(routes);

function App() {
  const [mixpanelInit, setMixpanelInit] = useState(false);
  const [mixpanelSubmitting, setMixpanelSubmitting] = useState(false);
  const { user, hasLoaded, authenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!mixpanelInit && !mixpanelSubmitting && hasLoaded && authenticated) {
      setMixpanelSubmitting(true);
      mixpanel.identify(user.uid);
      mixpanel.track("App view", {
        distinct_id: user.uid,
      });
      setMixpanelInit(true);
      setMixpanelSubmitting(false);
    }
  }, [user, hasLoaded, authenticated, mixpanelInit, mixpanelSubmitting]);

  return <RouterProvider router={router} />;
}

export default App;
