import React from "react";
import { TailSpin } from "react-loader-spinner";

const Spinner = ({ color, height }) => {
  return (
    <TailSpin
      color={color ? color : "#10B981"}
      height={height ? height : "18"}
      width={height ? height : "18"}
    />
  );
};

export default Spinner;
